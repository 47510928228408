// Copyright ID Business Solutions Ltd. 2024
import React, { createContext, useContext, useReducer } from 'react';

/**
 * The following provides minimal state handling which should be enough for most
 * simple/small applications. Try to ensure that your application stores as little
 * state as possible, in a lot of cases you should be able to manage without pulling
 * in an additional library. Note that the use of react-query or similar can be very
 * helpful in this respect.
 * See https://frontendmastery.com/posts/the-new-wave-of-react-state-management/ for
 * a good writeup on state handling (thanks to djones@idbs.com for sharing this link).
 */

const StoreContext = createContext();

export const INITIAL_STATE = {
  isPolar: false,
  isPolarFetching: true, // Initially true as application needs to wait for this to be loaded before displaying anything

  showConfigModal: false,

  configs: [],
  configsFetching: false,
  configsFetchError: false,

  configToDelete: null,
  configDeleting: false,

  configToUpdate: null,
  configUpdating: false,
  configUpdateError: false,
};

// The following actions will be used to trigger state changes
export const actions = {
  FETCH_IS_POLAR: 'fetch_is_polar',
  SET_IS_POLAR: 'set_is_polar',

  FETCH_CONFIGS: 'fetch_configs',
  SET_CONFIGS: 'set_configs',
  SET_CONFIGS_FETCH_ERROR: 'set_configs_fetch_error',

  SET_CONFIG_TO_DELETE: 'set_config_to_delete',
  DELETE_CONFIG: 'delete_config',
  RESET_DELETE_MODAL_STATE: 'reset_delete_modal_state',

  // Used for add/updating configs
  SET_CONFIG_TO_UPDATE: 'set_config_to_update',
  UPDATE_CONFIG: 'update_config',
  RESET_UPDATE_MODAL_STATE: 'reset_update_modal_state',
};

// Handles the state changes
export const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.FETCH_IS_POLAR:
      return {
        ...state,
        isPolarFetching: true,
      };
    case actions.SET_IS_POLAR:
      return {
        ...state,
        isPolarFetching: false,
        isPolar: payload,
      };
    case actions.FETCH_CONFIGS:
      return {
        ...state,
        configsFetching: true,
      };
    case actions.SET_CONFIGS:
      return {
        ...state,
        configsFetching: false,
        configsFetchError: false,
        configs: payload,
      };
    case actions.SET_CONFIGS_FETCH_ERROR:
      return {
        ...state,
        configsFetching: false,
        configsFetchError: payload,
      };
    case actions.SET_CONFIG_TO_DELETE:
      return {
        ...state,
        configToDelete: payload,
      };
    case actions.DELETE_CONFIG:
      return {
        ...state,
        configDeleting: true,
      };
    case actions.RESET_DELETE_MODAL_STATE:
      return {
        ...state,
        configToDelete: null,
        configDeleting: false,
      };

    case actions.SET_CONFIG_TO_UPDATE:
      return {
        ...state,
        configToUpdate: payload,
      };
    case actions.UPDATE_CONFIG:
      return {
        ...state,
        configUpdating: true,
      };
    case actions.RESET_UPDATE_MODAL_STATE:
      return {
        ...state,
        configToUpdate: null,
        configUpdating: false,
      };
    default:
      /* istanbul ignore next */
      throw new Error(`Unhandled action type: ${type}`);
  }
};

// Used at the top level of the application to initialise the state handling
export const StateProvider = ({ children, initialState = INITIAL_STATE, dispatchOverride }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StoreContext.Provider value={{ state, dispatch: dispatchOverride || dispatch }}>{children}</StoreContext.Provider>
  );
};

// Used by the application to gain access to the state and a mechanism (dispatch) via which state changes can be requested
export const useApplicationState = () => useContext(StoreContext);
