// Copyright ID Business Solutions Ltd. 2024
import React, { useEffect, useCallback, useState } from 'react';
import { ErrorPage, PageHeader, Button, Toaster } from '@idbs/idbs-react-components';
import { Dots } from '@idbs/idbs-web-components/react/Dots.js';
import { PlusIcon } from '@idbs/idbs-react-icon';
import { useIntlMessage } from '@idbs/idbs-react-hooks';
import handshakeIcon from './assets/handshake.svg';
import { Content, Shell, ButtonContainer, TableContainer } from './App.style';
import { Description } from './components/description/Description';
import { actions, useApplicationState } from './state/state';
import ConfigurationDialog from './components/dialog/configurationDialog/ConfigurationDialog';
import { ConfigurationTable } from './components/configuration/configurationTable/ConfigurationTable';
import DeleteConfirmationDialog from './components/dialog/confirmationDialog/deleteConfirmationDialog/DeleteConfirmationDialog';
import { RestApi } from './services/RestApi';

const App = () => {
  const intlMessage = useIntlMessage();

  // Used to stop loading page appearing after every config fetch
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const {
    dispatch,
    state: { configsFetchError, configsFetching, configs, isPolarFetching },
  } = useApplicationState();

  const getIsPolar = useCallback(async () => {
    try {
      const isPolar = await RestApi.getIsPolar();
      dispatch({ type: actions.SET_IS_POLAR, payload: isPolar });
    } catch (error) {
      dispatch({ type: actions.SET_IS_POLAR, payload: false });
    }
  }, [dispatch]);

  const getConfigs = useCallback(async () => {
    try {
      const newConfigs = await RestApi.getConfigs();
      dispatch({ type: actions.SET_CONFIGS, payload: newConfigs });
    } catch (e) {
      dispatch({ type: actions.SET_CONFIGS_FETCH_ERROR, payload: intlMessage(`${e.message}.title`) });
    }
  }, [dispatch, intlMessage]);

  useEffect(() => {
    dispatch({ type: actions.FETCH_CONFIGS });
    dispatch({ type: actions.FETCH_IS_POLAR });
  }, []);

  useEffect(() => {
    if (configsFetching) {
      getConfigs();
    }
  }, [configsFetching, getConfigs, configs]);

  useEffect(() => {
    if (isPolarFetching) {
      getIsPolar();
    }
  }, [isPolarFetching, getIsPolar]);

  useEffect(() => {
    if (!isPolarFetching && !configsFetching) {
      setIsInitialLoad(false);
    }
  }, [configsFetching, isInitialLoad, isPolarFetching]);

  /**
   * Following is a bit heavy handed, in reality you would probably want to handle this
   * in a slightly more user friendly way (error dialog, retry etc.) but this just demonstrates
   * handling errors via 'application state'
   */
  if (configsFetchError) {
    return (
      <ErrorPage
        testId='configs-fetch-error'
        heading={intlMessage('error.configs.fetch')}
        subHeading={configsFetchError.toString()}
      />
    );
  }

  return (
    <Shell data-testid='application-shell'>
      <Toaster />
      <PageHeader title={intlMessage('app.title')} icon={<img src={handshakeIcon} alt='application-icon' />} />
      {isInitialLoad && (isPolarFetching || configsFetching) ? (
        <Dots testId='loading-page' />
      ) : (
        <Content>
          <Description />
          <ButtonContainer>
            <Button
              testId='add-configuration-button'
              onClick={() => dispatch({ type: actions.SET_CONFIG_TO_UPDATE, payload: {} })}
              emphasis='primary'
              icon={<PlusIcon />}
            >
              {intlMessage('app.add.configuration')}
            </Button>
          </ButtonContainer>
          <TableContainer>
            <ConfigurationTable />
          </TableContainer>
          <ConfigurationDialog />
          <DeleteConfirmationDialog />
        </Content>
      )}
    </Shell>
  );
};

export { App };
