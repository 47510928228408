// Copyright ID Business Solutions Ltd. 2024
import React, { forwardRef } from 'react';
import {
  Dialog as DialogStyle,
  Footer as FooterStyle,
  Header as HeaderStyle,
  HeaderTitle,
  HeaderCloseButton,
  Body as BodyStyle,
  Main,
  DescriptionText,
} from './Dialog.style';
import { CrossIcon } from '@idbs/idbs-react-icon';

const Dialog = forwardRef(({ children, onHide, testId }, ref) => {
  return (
    <DialogStyle autoFocus ref={ref} onCancel={onHide} data-testid={testId}>
      {children}
    </DialogStyle>
  );
});

const Header = ({ title, onHide, testId, isLoading = false }) => {
  return (
    <HeaderStyle>
      <HeaderTitle>
        <span>{title}</span>
      </HeaderTitle>
      <HeaderCloseButton
        $isLoading={isLoading}
        data-testid={`${testId}-exit-button`}
        onClick={(e) => {
          if (isLoading) {
            e.preventDefault();
          } else {
            onHide();
          }
        }}
        aria-label='Close'
      >
        <CrossIcon height='16px' width='16px' />
      </HeaderCloseButton>
    </HeaderStyle>
  );
};

Dialog.Header = Header;

const Body = ({ description, children }) => {
  return (
    <BodyStyle>
      <DescriptionText>{description}</DescriptionText>
      {children}
    </BodyStyle>
  );
};

Dialog.Body = Body;
Dialog.Main = Main;

const Footer = ({ children }) => {
  return <FooterStyle>{children}</FooterStyle>;
};

Dialog.Footer = Footer;

export default Dialog;
