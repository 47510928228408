// Copyright ID Business Solutions Ltd. 2024
import React from 'react';
import { Container, DescriptionContainer } from './Description.style';
import { useIntlMessage } from '@idbs/idbs-react-hooks';
import { useApplicationState } from '../../state/state';

const Description = () => {
  const {
    state: { isPolar },
  } = useApplicationState();
  const intlMessage = useIntlMessage();

  return (
    <Container>
      <DescriptionContainer data-testid='custom-lookup-description'>
        <h2>{intlMessage(`app.custom.lookup.description.title`)}</h2>
        <p>{intlMessage(`app.${isPolar ? 'polar.' : ''}custom.lookup.description`)}</p>
      </DescriptionContainer>
      <DescriptionContainer data-testid='configure-lookup-description'>
        <h2>{intlMessage(`app.${isPolar ? 'polar.' : ''}custom.lookup.configure.description.title`)}</h2>
        <p>{intlMessage(`app.${isPolar ? 'polar.' : ''}custom.lookup.configure.description`)}</p>
      </DescriptionContainer>
    </Container>
  );
};

export { Description };
