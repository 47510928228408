// Copyright ID Business Solutions Ltd. 2024
import React from 'react';
import IdbsReactAdvancedTable from '@idbs/idbs-react-advanced-table';
import { IconContainer, IconButton } from './ConfigurationTable.style';
import { PencilIcon, DeleteIcon } from '@idbs/idbs-react-icon';
import { useIntlMessage } from '@idbs/idbs-react-hooks';
import { actions, useApplicationState } from '../../../state/state';

const ConfigurationTable = () => {
  const intlMessage = useIntlMessage();
  const {
    dispatch,
    state: { configs },
  } = useApplicationState();

  const createEditCellRenderer = () => {
    return (options) => {
      const { row } = options;
      return (
        <IconContainer>
          <IconButton
            icon={<PencilIcon />}
            emphasis='ghost'
            iconText={intlMessage('app.edit.configuration')}
            testId='edit-configuration-icon'
            onClick={() =>
              dispatch({
                type: actions.SET_CONFIG_TO_UPDATE,
                payload: row.original,
              })
            }
          />
          <IconButton
            icon={<DeleteIcon />}
            emphasis='ghost'
            iconText={intlMessage('app.delete.configuration')}
            testId='delete-configuration-icon'
            onClick={() => {
              dispatch({ type: actions.SET_CONFIG_TO_DELETE, payload: row.original });
            }}
          />
        </IconContainer>
      );
    };
  };

  const DefaultColumns = [
    {
      Header: intlMessage('app.configuration.name'),
      accessor: 'name',
    },
    {
      Header: intlMessage('app.configuration.description'),
      accessor: 'description',
    },
    {
      Cell: createEditCellRenderer(),
      Header: '',
      id: 'edit',
      isSortable: false,
      width: 50,
      maxWidth: 50,
      minWidth: 50,
    },
  ];

  return <IdbsReactAdvancedTable testId='configuration-table' columns={DefaultColumns} summaryData={configs} />;
};

export { ConfigurationTable };
