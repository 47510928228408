// Copyright ID Business Solutions Ltd. 2024
import React, { forwardRef } from 'react';
import { Button } from '@idbs/idbs-react-components';
import { useIntlMessage } from '@idbs/idbs-react-hooks';

import Dialog from '../Dialog';

const ConfirmationDialog = forwardRef(
  ({ title, description, closeButtonText, confirmButtonText, testId, onHide, onConfirm }, ref) => {
    const intlMessage = useIntlMessage();
    return (
      <Dialog ref={ref} onHide={onHide} testId={testId}>
        <Dialog.Header title={intlMessage(title)} onHide={onHide} testId={`${testId}-header`} />
        <Dialog.Body description={intlMessage(description)} />
        <Dialog.Footer>
          <Button testId={`${testId}-confirm-button`} emphasis='primary' onClick={onConfirm}>
            {intlMessage(confirmButtonText)}
          </Button>
          <Button testId={`${testId}-close-button`} onClick={onHide}>
            {intlMessage(closeButtonText)}
          </Button>
        </Dialog.Footer>
      </Dialog>
    );
  },
);

export default ConfirmationDialog;
