// Copyright ID Business Solutions Ltd. 2024
import styled from 'styled-components';

export const Dialog = styled.dialog`
  :host {
    --close-button-width: 1.875rem;
    --buttons-width: 6.25rem;
  }

  width: 500px;
  border: none;
  padding: 0;
  box-shadow: 0 0 0.625rem 0 var(--color-neutral-600);
  overflow: hidden;
  outline: none;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2.5rem 1fr 4rem;
  grid-gap: 0;

  &::backdrop {
    /** Can't use CSS custom properties here as ::backdrop doesn't inherit from :root where our properties are defined */
    background-color: rgb(67 74 84 / 80%);
  }

  &:not([open]) {
    display: none;
  }
`;

export const Header = styled.header`
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  background-color: var(--theme-main-800);
  color: var(--color-neutral-white);
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  padding: 1rem;
`;

export const HeaderTitle = styled.div`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HeaderCloseButton = styled.button`
  font-size: 30px;
  line-height: 16px;
  cursor: pointer;
  user-select: none;
  border: none;
  background: none;
  color: var(--color-neutral-white) !important;

  &:hover {
    ${(props) =>
      props.$isLoading
        ? 'cursor: not-allowed; color: var(--color-neutral-white) !important;'
        : 'color: var(--color-neutral-300) !important;'}
  }

  &:focus-visible {
    /* disable the focus-ring, normally this is bad practice as it hinders keyboard users, in this case
       this UI element is only really of any use to mouse users, if a keyboard user wants to quickly dismiss
       the dialog they will almost certainly use the Escape key */
    outline: none;
  }
`;

export const Body = styled.div`
  grid-area: 2 / 1 / 3 / 2;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 1rem 1.3rem;
  margin-bottom: 2rem;
  gap: 1.3rem;

  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

export const Main = styled.main`
  overflow-x: hidden;
`;

export const DescriptionText = styled.span`
  padding-top: 0.5rem;
  padding-bottom: 0.7rem;
`;

export const Footer = styled.footer`
  grid-area: 3 / 1 / 4 / 2;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  color: var(--color-neutral-600);
  font-style: normal;
  font-weight: 400
  background-color: var(--color-neutral-50);
  padding: 1rem;
  gap: 0.5rem;
`;
