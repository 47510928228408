// Copyright ID Business Solutions Ltd. 2024
import styled from 'styled-components';

export const Container = styled.div`
  grid-area: 1 / 1 / 1 / 8;
`;

export const DescriptionContainer = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;

  h2 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
`;
