import { Checkbox } from '@idbs/idbs-web-components/react/Checkbox.js';
import { Label } from '@idbs/idbs-react-components';
import { CheckboxContainer } from './Checkbox.style';
import { useIntlMessage } from '@idbs/idbs-react-hooks';

function MultiValueCheckboxes({ title, checkboxes, onChange, testId }) {
  const intlMessage = useIntlMessage();
  return (
    <div data-testid={testId}>
      <Label text={intlMessage(title)} title={intlMessage(title)} asterisk style={{ paddingBottom: '0.3125rem' }} />
      <CheckboxContainer>
        {checkboxes?.map((el) => {
          return (
            <Checkbox
              key={el.label}
              label={el.label}
              checked={el.checked}
              onCheckedChanged={(checked) => {
                onChange(el.label, checked);
              }}
            />
          );
        })}
      </CheckboxContainer>
    </div>
  );
}

export default MultiValueCheckboxes;
