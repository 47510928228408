/**
 * Determines the URL of the Config Service to call, by looking at window.location.hostname.
 *
 * @param tenantName The name of the tenant to get the URL for.
 * @returns The URL of the Config Service.
 */
export function getConfigServiceUrl(tenantName, hostname = window.location.hostname) {
  let environment = getEnvironment(hostname);
  if (!environment) {
    environment = 'qa';
  } else if (environment.startsWith('prod')) {
    environment = 'prod';
  } else if (environment.startsWith('staging')) {
    environment = 'staging';
  } else if (environment.startsWith('pentest')) {
    environment = 'pentest';
  } else {
    environment = 'qa';
  }

  let domainSuffix = 'cloud';
  if (['qa'].includes(environment)) {
    domainSuffix = 'dev';
  } else if (['pentest', 'staging'].includes(environment)) {
    domainSuffix = 'staging';
  }

  return `https://${tenantName}.config-service-${environment}.idbs-${domainSuffix}.com/v1/query`;
}

export function getEnvironment(hostname, hostnamePrefix = 'idbs-chem-lookup-admin-') {
  if (!hostname) {
    return null;
  }

  if (hostname === 'localhost') {
    return 'localhost';
  }

  const domainParts = hostname.split('.');
  if (!domainParts || domainParts.length < 1) {
    return null;
  }

  const serviceName = domainParts[0];
  if (serviceName.indexOf(hostnamePrefix) < 0) {
    return null;
  }
  return serviceName.replace(hostnamePrefix, '');
}

/**
 * Determines the tenant name from the URL
 * @param {*} urlPath
 */
export function getTenantName(urlPath) {
  if (urlPath.startsWith('/')) {
    // eslint-disable-next-line no-param-reassign
    urlPath = urlPath.substring(1);
  }
  const pathComponents = urlPath.split('/');

  let tenantName;
  if (pathComponents.length > 0) {
    const trimmedTenantName = pathComponents[0].trim();
    if (trimmedTenantName.length > 0) {
      tenantName = trimmedTenantName;
    }
  }

  return tenantName;
}

/**
 * Helper function that processes the JSON response from the ConfigService (which returns a standard
 * GraphQL response).
 *
 * @param result The result received from the ConfigService, as decoded JSON.
 * @returns An object of the key: value pairs. e.g. { theme: "themeUrl", user_management: "true" }
 * @throws An Error if the result contains an errors property
 */
export function processResult(result) {
  if (result.errors) {
    throw Error(`Error received: ${JSON.stringify(result.errors)}`);
  }

  return Object.keys(result.data).reduce((accumulator, key) => {
    const value = result.data[key];
    accumulator[key] = value ? value.url : null;
    return accumulator;
  }, {});
}
