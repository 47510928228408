// Copyright ID Business Solutions Ltd. 2024
import React from 'react';
import { Tooltip } from '@idbs/idbs-web-components/react/Tooltip.js';
import { InfoIcon } from '@idbs/idbs-react-icon';
import { useIntlMessage } from '@idbs/idbs-react-hooks';
import { IconContainer, TooltipTitle, TooltipDesc } from './Popover.style';

const Popover = ({ testId }) => {
  const intlMessage = useIntlMessage();
  return (
    <Tooltip placement='right' theme='light' trigger='hover'>
      <div data-testid={testId} slot='content'>
        <TooltipTitle>{intlMessage('app.configuration.dialog.api.key.info.title')}</TooltipTitle>
        <TooltipDesc>{intlMessage('app.configuration.dialog.api.key.info.description')}</TooltipDesc>
      </div>
      <IconContainer>
        <InfoIcon width='16px' height='16px' />
      </IconContainer>
    </Tooltip>
  );
};

export default Popover;
