/**
 * Gets the hostname of the back-end custom-lookup API, given the hostname of the front-end. Relies on the window
 * object having a _CUSTOM_LOOKUP_SERVICE property, which is a map of environment -> back-end API host.
 *
 * @param frontEndHost The hostname that the front-end is served from
 * @throws {Error}
 * @returns {string}
 */
const getApiHost = (frontEndHost) => {
  let env = frontEndHost?.split('.')[0];

  if (!env) {
    throw new Error('Unknown host');
  }

  env = env.replace('idbs-chem-lookup-admin-', '');

  const apiHost = window._CUSTOM_LOOKUP_SERVICE[env];
  if (!apiHost) {
    throw new Error('Unknown host');
  }

  return apiHost;
};

/**
 * Gets the base URL of the back-end user admin API, given the hostname of the front-end. Relies on the window
 * object having a _CUSTOM_LOOKUP_SERVICE property, which is a map of environment -> back-end API host.
 *
 * It supports both GxP and non-GxP services
 *
 * @param frontEndHost The hostname that the front-end is served from (by default, the current browser location host)
 * @returns The back-end custom-lookup API URL
 */
const getApiBaseUrl = (frontEndHost = window.location.host) => {
  return `${window.location.protocol}//${getApiHost(frontEndHost)}`;
};

// Formatted in this way to allow mocking in Cypress
export const ApiUtils = { getApiBaseUrl, getApiHost };
