/* eslint-disable max-lines-per-function */
// Copyright ID Business Solutions Ltd. 2024
import React, { useRef, useEffect, useCallback } from 'react';
import { Button, TextInput, toast } from '@idbs/idbs-react-components';
import { useIntlMessage } from '@idbs/idbs-react-hooks';
import { actions, useApplicationState } from '../../../state/state';
import {
  invalidateConfigurationNameInput,
  invalidateDescriptionInput,
  invalidateHostInput,
  invalidateApiKeyInput,
} from '../../../utils/InputValidationUtils';
import Dialog from '../Dialog';
import ConfirmationDialog from '../../dialog/confirmationDialog/ConfirmationDialog';
import { InputContainer, CheckboxContainer, ApiKeyContainer } from './ConfigurationDialog.style';
import Popover from './popover/Popover';
import { useFormInput } from '../../../hooks/useFormInput';
import { RestApi } from '../../../services/RestApi';
import LoadingButton from '../../loadingButton/LoadingButton';
import MultiValueCheckboxes from './multiValueCheckboxes/MultiValueCheckboxes';
import { DEFAULT_SUPPORTED_SEARCH_TYPES, DEFAULT_SUPPORTED_STRUCTURE_FORMATS } from '../../../utils/FormUtils';
import { useFormCheckboxes } from '../../../hooks/useFormCheckboxes';

const API_KEY_MASK = '************************************************************';

const ConfigurationDialog = () => {
  const confirmationRef = useRef();
  const ref = useRef();
  const intlMessage = useIntlMessage();
  const {
    dispatch,
    state: { configs, configToUpdate, configUpdating },
  } = useApplicationState();

  const {
    value: configurationInput,
    onChange: setConfigurationInput,
    error: configurationError,
    hasChange: configurationHasChange,
  } = useFormInput(configToUpdate ? configToUpdate.name : '', (value) => {
    return isDuplicateName(value)
      ? 'app.configuration.dialog.name.unique.error'
      : invalidateConfigurationNameInput(value);
  });

  const {
    value: descriptionInput,
    onChange: setDescriptionInput,
    error: descriptionError,
    hasChange: descriptionHasChange,
  } = useFormInput(configToUpdate ? configToUpdate.description : '', (value) => {
    return invalidateDescriptionInput(value);
  });

  const {
    value: hostInput,
    onChange: setHostInput,
    error: hostError,
    hasChange: hostHasChange,
  } = useFormInput(configToUpdate ? configToUpdate.host : '', (value) => {
    return invalidateHostInput(value);
  });

  const {
    value: apiKeyInput,
    onChange: setApiKeyInput,
    error: apiKeyError,
    hasChange: apiKeyHasChange,
    hasBeenUpdated: apiKeyHasBeenUpdated,
  } = useFormInput(configToUpdate?.name ? API_KEY_MASK : '', (value) => {
    if (configToUpdate?.api_key && !apiKeyHasChange && !apiKeyHasBeenUpdated) {
      return;
    }
    return invalidateApiKeyInput(value);
  });

  const {
    values: searchTypes,
    onChange: setSearchTypes,
    hasChange: searchTypeHasChange,
    reset: searchTypeReset,
  } = useFormCheckboxes(configToUpdate ? configToUpdate.supported_search_types : [], DEFAULT_SUPPORTED_SEARCH_TYPES);

  const {
    values: structureFormats,
    onChange: setStructureFormats,
    hasChange: structureFormatsHasChange,
    reset: structureFormatsReset,
  } = useFormCheckboxes(
    configToUpdate ? configToUpdate.supported_structure_formats : [],
    DEFAULT_SUPPORTED_STRUCTURE_FORMATS,
  );

  const isDuplicateName = useCallback(
    (name) => {
      return configs?.some((config) => config.name === name);
    },
    [configs],
  );

  const hasAnyChange = useCallback(() => {
    return (
      configurationHasChange ||
      descriptionHasChange ||
      hostHasChange ||
      apiKeyHasChange ||
      searchTypeHasChange ||
      structureFormatsHasChange
    );
  }, [
    apiKeyHasChange,
    configurationHasChange,
    descriptionHasChange,
    hostHasChange,
    searchTypeHasChange,
    structureFormatsHasChange,
  ]);

  const hasAnyError = useCallback(() => {
    return configurationError || descriptionError || hostError || apiKeyError;
  }, [apiKeyError, configurationError, descriptionError, hostError]);

  const saveConfig = useCallback(async () => {
    try {
      await RestApi.saveConfig({
        name: configurationInput,
        description: descriptionInput || '',
        host: hostInput,
        ...(apiKeyInput !== API_KEY_MASK && { api_key: apiKeyInput }),
        supported_search_types: searchTypes.filter((el) => el.checked).map((el) => el.label),
        supported_structure_formats: structureFormats.filter((el) => el.checked).map((el) => el.label),
      });
      dispatch({ type: actions.FETCH_CONFIGS });
    } catch (e) {
      toast.error(intlMessage(`${e.message}.title`), intlMessage(`${e.message}.message`));
    } finally {
      dispatch({ type: actions.RESET_UPDATE_MODAL_STATE });
    }
  }, [
    configurationInput,
    descriptionInput,
    hostInput,
    apiKeyInput,
    searchTypes,
    structureFormats,
    dispatch,
    intlMessage,
  ]);

  useEffect(() => {
    if (configUpdating) {
      saveConfig();
    }
  }, [configUpdating, saveConfig]);

  useEffect(() => {
    if (configToUpdate) {
      ref.current.showModal();
    } else {
      setConfigurationInput('');
      setDescriptionInput('');
      setHostInput('');
      setApiKeyInput('');
      searchTypeReset();
      structureFormatsReset();

      ref.current.close();
    }
  }, [
    configToUpdate,
    setApiKeyInput,
    setConfigurationInput,
    setDescriptionInput,
    setHostInput,
    searchTypeReset,
    structureFormatsReset,
  ]);

  return (
    <Dialog
      autoFocus
      ref={ref}
      onHide={(e) => {
        e.preventDefault();
        if (!configUpdating) {
          if (hasAnyChange()) {
            confirmationRef.current?.showModal();
          } else {
            dispatch({ type: actions.RESET_UPDATE_MODAL_STATE });
          }
        }
      }}
      testId='configuration-dialog'
    >
      <ConfirmationDialog
        title={'app.unsaved.changes'}
        description={'app.unsaved.changes.description'}
        confirmButtonText={'app.continue'}
        closeButtonText={'app.close.dialog'}
        ref={confirmationRef}
        onHide={(e) => {
          // Stop event bubbling - otherwise this'll close the parent dialog
          e?.stopPropagation();
          confirmationRef.current.close();
          dispatch({ type: actions.RESET_UPDATE_MODAL_STATE });
        }}
        onConfirm={() => {
          confirmationRef.current.close();
        }}
        testId='confirmation-dialog'
      />
      <Dialog.Header
        title={configToUpdate?.name ? intlMessage('app.edit.configuration') : intlMessage('app.add.configuration')}
        onHide={() => dispatch({ type: actions.RESET_UPDATE_MODAL_STATE })}
        isLoading={configUpdating}
        testId={'configuration-dialog'}
      />
      <Dialog.Main>
        <Dialog.Body description={intlMessage('app.configuration.dialog.description')}>
          <InputContainer>
            <TextInput
              inputTestId='configuration-name-input'
              label={intlMessage('app.configuration.dialog.configuration.name')}
              placeholder={intlMessage('app.configuration.dialog.configuration.name')}
              onChange={setConfigurationInput}
              value={configurationInput}
              error={configurationError && intlMessage(configurationError)}
              disabled={!!configToUpdate?.name}
              required
            />
            <TextInput
              inputTestId='configuration-description-input'
              label={intlMessage('app.configuration.description')}
              placeholder={intlMessage('app.configuration.description')}
              onChange={setDescriptionInput}
              value={descriptionInput}
              error={descriptionError && intlMessage(descriptionError)}
            />
            <TextInput
              inputTestId='configuration-host-input'
              label={intlMessage('app.configuration.dialog.configuration.host')}
              placeholder='https://'
              onChange={setHostInput}
              value={hostInput}
              error={hostError && intlMessage(hostError)}
              required
            />

            <ApiKeyContainer data-testid='api-key-input-container'>
              <TextInput
                inputTestId='configuration-api-key-input'
                label={intlMessage('app.configuration.dialog.configuration.api.key')}
                placeholder={intlMessage('app.configuration.dialog.configuration.api.key')}
                onChange={(e) => {
                  // API is the initial value & there has been no changes to the field
                  // Clear the input field when we start typing
                  if (!apiKeyHasChange && !apiKeyHasBeenUpdated) {
                    return setApiKeyInput('');
                  }
                  setApiKeyInput(e);
                }}
                value={apiKeyInput}
                error={apiKeyError && intlMessage(apiKeyError)}
                onClick={() => {
                  if (configToUpdate?.name && !hasAnyChange()) {
                    setApiKeyInput('');
                  }
                }}
                required
              />
              <Popover testId={'api-key-help-popover'} />
            </ApiKeyContainer>
          </InputContainer>
          <CheckboxContainer>
            <MultiValueCheckboxes
              title={'app.configuration.dialog.supported.search.types.title'}
              checkboxes={searchTypes}
              onChange={setSearchTypes}
              testId={'search-types-checkboxes'}
            />
            <MultiValueCheckboxes
              title={'app.configuration.dialog.supported.structure.formats.title'}
              checkboxes={structureFormats}
              onChange={setStructureFormats}
              testId={'structure-formats-checkboxes'}
            />
          </CheckboxContainer>
        </Dialog.Body>
      </Dialog.Main>
      <Dialog.Footer>
        {configUpdating ? (
          <LoadingButton />
        ) : (
          <Button
            testId='configuration-dialog-save-button'
            emphasis='primary'
            disabled={
              !configurationInput ||
              !hostInput ||
              !apiKeyInput ||
              hasAnyError() ||
              !hasAnyChange() ||
              searchTypes.filter((el) => el.checked).length <= 0 ||
              structureFormats.filter((el) => el.checked).length <= 0
            }
            onClick={() => {
              dispatch({ type: actions.UPDATE_CONFIG });
            }}
          >
            {intlMessage('app.save')}
          </Button>
        )}
        <Button
          disabled={configUpdating}
          testId='configuration-dialog-cancel-button'
          onClick={() => {
            if (hasAnyChange()) {
              confirmationRef.current?.showModal();
            } else {
              dispatch({ type: actions.RESET_UPDATE_MODAL_STATE });
            }
          }}
        >
          {intlMessage('app.cancel')}
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};
export default ConfigurationDialog;
