// Copyright ID Business Solutions Ltd. 2024

/**
 * Method used to test if the input text provided is invalid
 * @param {string} input
 * @returns string
 */
export function invalidateConfigurationNameInput(input) {
  if (input === '') {
    return '';
  }

  if (!/^[a-z]$/.test(input.charAt(0))) {
    return 'app.configuration.dialog.name.begin.with.character.error';
  }

  if (input.length < 5) {
    return 'app.configuration.dialog.name.min.length.error';
  }

  if (input.length > 30) {
    return 'app.configuration.dialog.name.max.length.error';
  }

  if (!/^[a-z]([a-z0-9-])*$/.test(input)) {
    return 'app.configuration.dialog.name.valid.characters.error';
  }

  return '';
}

/**
 * Method used to test if the input text provided is invalid
 * @param {string} input
 * @returns string
 */
export function invalidateDescriptionInput(input) {
  if (input === '') {
    return '';
  }

  if (!/^([\w0-9 .,]*)$/.test(input)) {
    return 'app.configuration.dialog.description.valid.characters.error';
  }

  if (input.length > 50) {
    return 'app.configuration.dialog.description.max.length.error';
  }

  return '';
}

/**
 * Method used to test if the input text provided is invalid
 * @param {string} input
 * @returns string
 */
export function invalidateHostInput(input) {
  if (input === '') {
    return '';
  }

  if (!(input.startsWith('https://') && input.includes('.'))) {
    return 'app.configuration.dialog.host.https.error';
  }

  if (!/^([\w./:-]*)$/.test(input)) {
    return 'app.configuration.dialog.host.valid.characters.error';
  }

  if (input.length < 15) {
    return 'app.configuration.dialog.host.min.length.error';
  }

  if (input.length > 100) {
    return 'app.configuration.dialog.host.max.length.error';
  }
}

/**
 * Method used to test if the input text provided is invalid
 * @param {string} input
 * @returns string
 */
export function invalidateApiKeyInput(input) {
  if (input === '') {
    return '';
  }

  if (!/^([a-zA-Z0-9.-]*)$/.test(input)) {
    return 'app.configuration.dialog.api.key.valid.characters.error';
  }

  if (input.length < 32) {
    return 'app.configuration.dialog.api.key.min.length.error';
  }

  if (input.length > 128) {
    return 'app.configuration.dialog.api.key.max.length.error';
  }

  return '';
}
