export class BadRequestError extends Error {
  constructor() {
    super('app.bad.request');
    this.name = 'BadRequestError';
  }
}

export class UnauthorizedError extends Error {
  constructor() {
    super('app.unauthorized.request');
    this.name = 'UnauthorizedError';
  }
}

export class ForbiddenError extends Error {
  constructor() {
    super('app.forbidden.request');
    this.name = 'ForbiddenError';
  }
}

export class InternalServerError extends Error {
  constructor() {
    super('app.internal.error');
    this.name = 'InternalServerError';
  }
}
