// Copyright ID Business Solutions Ltd. 2024
import React, { useEffect, useRef, useCallback } from 'react';
import { Button, toast } from '@idbs/idbs-react-components';
import { useIntlMessage } from '@idbs/idbs-react-hooks';
import { RestApi } from '../../../../services/RestApi';
import Dialog from '../../Dialog';
import { actions, useApplicationState } from '../../../../state/state';
import LoadingButton from '../../../loadingButton/LoadingButton';

const DeleteConfirmationDialog = () => {
  const ref = useRef();
  const intlMessage = useIntlMessage();
  const {
    dispatch,
    state: { configs, configToDelete, configDeleting },
  } = useApplicationState();

  const removeConfig = useCallback(async () => {
    try {
      const targetConfig = configs.filter((config) => config.name === configToDelete?.name)[0];
      if (targetConfig) {
        await RestApi.removeConfig(targetConfig.name);
        dispatch({ type: actions.FETCH_CONFIGS });
      }
    } catch (e) {
      toast.error(intlMessage(`${e.message}.title`), intlMessage(`${e.message}.message`));
    } finally {
      dispatch({ type: actions.RESET_DELETE_MODAL_STATE });
    }
  }, [configToDelete, configs, dispatch, intlMessage]);

  useEffect(() => {
    if (configDeleting) {
      removeConfig();
    }
  }, [configDeleting, removeConfig]);

  useEffect(() => {
    if (configToDelete) {
      ref.current.showModal();
    } else {
      ref.current.close();
    }
  }, [configToDelete, dispatch]);

  return (
    <Dialog
      ref={ref}
      onHide={(e) => {
        e.preventDefault();
        if (!configDeleting) {
          dispatch({ type: actions.RESET_DELETE_MODAL_STATE });
        }
      }}
      testId='delete-dialog'
    >
      <Dialog.Header
        isLoading={configDeleting}
        title={intlMessage('app.delete.configuration')}
        onHide={() => dispatch({ type: actions.RESET_DELETE_MODAL_STATE })}
        testId='delete-dialog-header'
      />
      <Dialog.Body description={intlMessage('app.delete.configuration.description')} />
      <Dialog.Footer>
        {configDeleting ? (
          <LoadingButton />
        ) : (
          <Button
            testId='delete-dialog-confirm-button'
            emphasis='primary'
            onClick={() => {
              dispatch({ type: actions.DELETE_CONFIG });
            }}
          >
            {intlMessage('app.delete')}
          </Button>
        )}
        <Button
          disabled={configDeleting}
          testId='delete-dialog-close-button'
          onClick={() =>
            dispatch({
              type: actions.RESET_DELETE_MODAL_STATE,
            })
          }
        >
          {intlMessage('app.cancel')}
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
