// Copyright ID Business Solutions Ltd. 2024
import styled from 'styled-components';

export const Shell = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding: 0 1.3rem;
  overflow: hidden;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 0.5fr 0.1fr 0.1fr;
  grid-column-gap: 0px;
  grid-row-gap: 5px;

  @media (max-width: 1366px) {
    grid-template-columns: auto;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  padding: 1.5rem 0;

  grid-area: 2 / 1 / 3 / 8;
  overflow-y; auto;
`;

export const TableContainer = styled.div`
  display: flex;
  justify-content: start;
  position: relative;
  height: 18.375rem;
  overflow: hidden;

  grid-area: 3 / 1 / 4 / 8;

  .summary-table {
    // Override the styling of idbs-react-advanced-table so that it fits our requirements
    width: 100%;
    border: 1px solid var(--color-neutral-300);

    .table {
      min-width: unset !important; // Need this to stop the table setting its own 'wrong' min-width
    }
  }
`;
