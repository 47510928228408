// Copyright ID Business Solutions Ltd. 2024
import { styled } from 'styled-components';

export const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: var(--theme-main-800);
  }
`;

export const TooltipTitle = styled.h1`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

export const TooltipDesc = styled.h1`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;
