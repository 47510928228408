// Copyright ID Business Solutions Ltd. 2024
import { ApiUtils } from '../utils/ApiUtils';
import { getConfigServiceUrl, getTenantName, processResult } from '../utils/ConfigServiceUtils';
import { BadRequestError, UnauthorizedError, ForbiddenError, InternalServerError } from './RestApiError';

const getHttpHeaders = () =>
  /**
   * In reality, you will almost certainly need to pass an 'Authorization' header here
   * (probably with a PAS token). See https://github.com/IDBusinessSolutions/idbs-auth-js
   * for details of the IDBS library you should use if PAS is needed.
   */
  new Headers({
    Accept: 'application/json',
    Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('access_token'))}`,
  });

/**
 *
 * @param {number} statusCode Response status code
 * @returns {BadRequestError | UnauthorizedError | ForbiddenError | InternalServerError}.
 */
function getError(statusCode) {
  switch (statusCode) {
    case 400: {
      return new BadRequestError();
    }
    case 401: {
      return new UnauthorizedError();
    }
    case 403: {
      return new ForbiddenError();
    }
    default: {
      return new InternalServerError();
    }
  }
}

export class RestApi {
  /**
   * @typedef {Object} Configuration
   * @property {string} configurationName
   * @property {string} description
   * @property {string} host
   * @property {string} api_key
   *
   */

  /**
   * Will fetch saved configuration objects.
   * @returns {Configuration[] | []} Returns an array of configuration objects
   * @throws {BadRequestError | UnauthorizedError | ForbiddenError | InternalServerError}
   */
  static async getConfigs() {
    let statusCode;
    try {
      const response = await fetch(`${ApiUtils.getApiBaseUrl()}/config`, { headers: getHttpHeaders() });
      if (response.ok) {
        return response.json();
      }

      statusCode = response.status || 400;
    } catch (e) {
      /* istanbul ignore next */
      throw new BadRequestError();
    }

    throw getError(statusCode);
  }

  /**
   * Will request to remove a configuration.
   * @param {string} name name of the selected configuration
   * @throws {BadRequestError | UnauthorizedError | ForbiddenError | InternalServerError}
   */
  static async removeConfig(name) {
    let statusCode;
    try {
      const response = await fetch(`${ApiUtils.getApiBaseUrl()}/config/data-source?name=${name}`, {
        headers: getHttpHeaders(),
        method: 'DELETE',
      });
      if (response.ok) {
        return;
      }

      statusCode = response.status || 400;
    } catch (e) {
      /* istanbul ignore catch */
      throw new BadRequestError();
    }
    throw getError(statusCode);
  }

  /**
   * Will request to save a configuration option.
   * @param {Configuration} configuration
   * @throws {BadRequestError | UnauthorizedError | ForbiddenError | InternalServerError}
   */
  static async saveConfig(configuration) {
    let statusCode;
    try {
      const response = await fetch(`${ApiUtils.getApiBaseUrl()}/config/data-source`, {
        headers: getHttpHeaders(),
        method: 'POST',
        body: JSON.stringify(configuration),
      });
      statusCode = response.status || 400;

      if (response.ok) {
        return;
      }
    } catch (e) {
      /* istanbul ignore next */
      throw new BadRequestError();
    }

    throw getError(statusCode);
  }

  static async getIsPolar() {
    const data = {
      query: `query { theme: getServiceRegistry(id: "theme") { url }}`,
    };

    const url = getConfigServiceUrl(getTenantName(window.location.pathname));

    try {
      const response = await fetch(url, {
        headers: getHttpHeaders(),
        method: 'POST',
        body: JSON.stringify(data),
      });
      if (response.ok) {
        const config = processResult(await response.json());
        const themeUrl = config.theme;
        const themeType = themeUrl ? themeUrl.substring(themeUrl.lastIndexOf('/') + 1).toLowerCase() : null;

        console.debug(`Theme type: ${themeType}`);
        return themeType;
      }
    } catch (error) {
      /* empty */
    }

    console.log('Error when querying the config service, defaulting to EWB');
    return false;
  }
}
