// Copyright ID Business Solutions Ltd. 2024

import { styled } from 'styled-components';

import { Button } from '@idbs/idbs-react-components';

const Spinner = styled.div`
  border: 3px solid #ffffff66;
  border-top: 3px solid #ffffff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;

  &:hover,
  &:active {
    background-color: var(--theme-main-800) !important;
    cursor: not-allowed;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const StyledButton = styled(Button)`
  width: 55px;
  padding: 0 !important;
  justify-content: center;

  &:hover,
  &:active {
    background-color: var(--theme-main-800) !important;
    cursor: not-allowed;
  }
`;

function LoadingButton() {
  return (
    <StyledButton emphasis='primary' testId={'loading-button'}>
      <Spinner />
    </StyledButton>
  );
}
export default LoadingButton;
