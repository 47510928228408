/**
 * @typedef Checkbox
 * @property {string} label - Name value for a checkbox, usually capitalised
 * @property {boolean} checked - Determines if the checkbox is enabled
 */

export const DEFAULT_SUPPORTED_SEARCH_TYPES = [
  { label: 'NAME', checked: false },
  { label: 'STRUCTURE', checked: false },
  { label: 'SUBSTRUCTURE', checked: false },
];

export const DEFAULT_SUPPORTED_STRUCTURE_FORMATS = [
  { label: 'SMILES', checked: false },
  { label: 'INCHI', checked: false },
  { label: 'MOL', checked: false },
  { label: 'MRV', checked: false },
  { label: 'IDBS_HEX', checked: false },
  { label: 'BIO_ID', checked: false },
  { label: 'CML', checked: false },
];

/**
 * Used to convert array of checkbox labels into the supported rendered format
 * @param {String[]} input List of saved supported formats
 * @param {Checkbox[]} defaultArray
 * @returns {Checkbox[]}
 */
export function transformSaveCheckboxData(inputArray, defaultArray) {
  if (!inputArray || inputArray.length <= 0) {
    return defaultArray;
  }

  return defaultArray.map((el) => {
    if (inputArray.includes(el.label)) {
      return { ...el, checked: true };
    }

    return el;
  });
}
