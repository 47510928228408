// Copyright ID Business Solutions Ltd. 2024
import React from 'react';
import ReactDOM from 'react-dom/client';
import { registerPlugin, getTenantIdFromPath } from '@idbs/idbs-admin-portal-plugin-sdk';
import '@idbs/idbs-analytics-app/idbs-analytics-app.js';
import '@idbs/idbs-themed-app/idbs-themed-app.js';
import { ApplicationErrorBoundary } from '@idbs/idbs-react-components';
import { IntlProvider } from '@idbs/idbs-react-hooks';

// Pull in your applications default style - this will be applied on startup and if the config service is configured
// and a theme is specified for the tenant, then the style specified by the theme will be used to override this default
import '@idbs/idbs-themes/styles/polar/style.css';

import { StateProvider } from './state/state';
import { App } from './App';
import './index.css';
import translations from './translations';

/*
 * See https://github.com/IDBusinessSolutions/idbs-analytics-app for instructions on how to
 * setup google analytics for your application.
 * Also see https://idbs-hub.atlassian.net/wiki/spaces/WEBDEV/pages/3520823396/Google+Tag+Manager+Application+Analytics
 * for further instructions and information that will be helpful
 */
const analyticsId = '';
const configServiceUrl = '';

// Wait for our mock service worker to start before doing anything else, note this
// only has any effect in 'dev' mode, in 'prod' mode the promise will resolve instantly
const prepare = async () => {
  if (process.env.NODE_ENV === 'development') {
    const { worker } = await import('./__mocks__/browser');
    return worker.start();
  }

  return Promise.resolve();
};

// Render the application
const renderApplication = () => {
  /**
   * Note that idbs-analytics-app & idbs-themed-app need a PAS token in order to access
   * the config service, they expect this PAS token to be stored in sessionStorage under
   * an 'access_token' key. This PAS token must also have " characters around the token
   * string
   */
  ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
      <idbs-analytics-app analyticsid={analyticsId} configurl={configServiceUrl} />
      <idbs-themed-app configurl={configServiceUrl}>
        <ApplicationErrorBoundary>
          <IntlProvider translations={translations}>
            <StateProvider>
              <App />
            </StateProvider>
          </IntlProvider>
        </ApplicationErrorBoundary>
        {/* The following will ensure that the app isn't rendered until AFTER any themeing
            has been applied by the idbs-themed-app web component. Doing this prevents a
            'flash' of style change if the apps default styling doesn't match what the
            config service returns (i.e. app is displayed initially as ELN but then switches
            to Polar). This does cause a slight delay in the display of the app as it has to
            wait for a response/failure from the config service (if configured), but this is
            preferable to the style 'flash'. If this is not acceptable, then just remove the
            'placeholder' div below */}
        <div slot='placeholder' />
      </idbs-themed-app>
    </React.StrictMode>,
  );
};

const onPluginRegisterError = (error) => {
  console.warn(`Could not load page: ${JSON.stringify(error)}`);
};

prepare().then(() => {
  const tenantName = getTenantIdFromPath(window.location.pathname);
  registerPlugin(tenantName, renderApplication, onPluginRegisterError);
});
