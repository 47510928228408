// Copyright ID Business Solutions Ltd. 2024

import { useEffect, useState } from 'react';

/**
 * @typedef {Object} InputProps
 * @property {string} value The input value
 * @property {boolean} hasChange The field is dirty
 * @property {boolean} hasBeenUpdated The field has been updated at least once
 * @property { Function } onChange Method to call to update input value
 * @property { error } error Error value return from the provided validator
 */

/**
 * @param {string} initialValue Initial input value
 * @param {Function} validator Function used to validate input string
 * @returns { InputProps }
 */
export function useFormInput(initialValue, validator) {
  const [hasChange, setHasChange] = useState(false);
  const [hasBeenUpdated, setHasBeenUpdated] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState('');

  // Not required if component gets mounted/unmounted.
  // This is currently not the case for this hooks use in ConfigurationDialog
  useEffect(() => {
    setValue(initialValue);
    setHasBeenUpdated(false);
  }, [initialValue]);

  function handleChange(text) {
    !hasBeenUpdated && setHasBeenUpdated(true);
    setHasChange(initialValue !== text);
    setValue(text);
    setError(validator(text));
  }

  const inputProps = {
    value,
    hasChange,
    hasBeenUpdated,
    onChange: handleChange,
    error,
  };

  return inputProps;
}
