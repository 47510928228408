// Copyright ID Business Solutions Ltd. 2024

import { styled } from 'styled-components';

import { Button } from '@idbs/idbs-react-components';

export const IconContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;
export const IconButton = styled(Button)`
  width: 32px;
  height: 32px;
`;
