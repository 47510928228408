// Copyright ID Business Solutions Ltd. 2024

import { useEffect, useRef, useState } from 'react';
import { transformSaveCheckboxData } from '../utils/FormUtils';

/**
 * @typedef {Object} InputProps
 * @property {import('../utils/FormUtils').Checkbox[]} values The input value
 * @property {boolean} hasChange The field is dirty
 * @property { Function } onChange Method to call to update input value
 */

/**
 * @param {import('../utils/FormUtils').Checkbox[]} initialValue Initial input value
 * @param {import('../utils/FormUtils').Checkbox[]} defaultValue
 * @returns { InputProps }
 */
export function useFormCheckboxes(initialValue, defaultValue) {
  const [hasChange, setHasChange] = useState(false);
  const [values, setValues] = useState(initialValue);
  const transformedInitialValue = useRef();

  // Not required if component gets mounted/unmounted.
  // This is currently not the case for this hooks use in ConfigurationDialog
  useEffect(() => {
    const transformedData = transformSaveCheckboxData(initialValue, defaultValue);
    setValues(transformedData);

    transformedInitialValue.current = transformedData.reduce((map, el) => {
      map[el.label] = el.checked;
      return map;
    }, {});
  }, [defaultValue, initialValue]);

  function handleChange(name, checked) {
    const updatedValues = values.map((item) => (item.label === name ? { label: name, checked } : item));

    setValues(updatedValues);
    setHasChange(!updatedValues.every((el) => el.checked === transformedInitialValue.current[el.label]));
  }

  // Reset any state for when the dialog is removed from the screen
  function reset() {
    setValues(null);
    setHasChange(false);
    transformedInitialValue.current = null;
  }

  const inputProps = {
    values,
    hasChange,
    onChange: handleChange,
    reset,
  };

  return inputProps;
}
